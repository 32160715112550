@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500&family=Roboto+Slab&display=swap);
body {
  margin: 0;
  background: #ffae00;
  font-family: 'Fira Code', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #000F37;
}

p {
  color: #000F37;
  font-size: 16px;
  max-width: 700px;
  padding: 0 20px;
  margin: 0 auto;
}

a {
  color: #2e3866;
  text-decoration: none;
  font-size: inherit;
}

strong {
  color: #2e3866;
  text-decoration: none;
  border: none;
  background: none;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
.App {
  text-align: center;
}

img {
  max-width: 700px;
  width: 90vw;
}

.heading {
  font-size: 1.5em;
  color: #000F37;
  padding: 0 20px;

  display: flex;
  flex-wrap: nowrap;

  align-items: center;
  justify-content: center;
}

.title {
  padding-bottom: 15px;
}

.heading-deco-1 > svg {
  width: 30px;
  height: 60px;
}

.heading-deco-2 > svg {
  width: 60px;
  height: 60px;
}

@media (max-width: 500px) {
  .heading-deco-1, .heading-deco-2 {
    display: none;
  }
}

.card {
  background: #e3e8fc;
  border-radius: 5px;
  box-shadow: 5px 5px 6px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 7px;
}

.close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.close > svg {
  fill: #000F37;
  width: 20px;
}

.form {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 80%;
}

input, textarea, button {
  height: 20px;
  margin: 10px 0;
}

input, textarea {
  border: solid 1px #000F37;
  border-radius: 5px;
  width: 100%;
}

textarea {
  height: 100px;
  resize: vertical;
}

button {
  background: #000F37;
  border: none;
  border-radius: 5px;
  color: #e3e8fc;
}

.credits {
  position: relative;
  margin: 0 auto;
  bottom: -50px;
  font-size: 9px;
}


