@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500&family=Roboto+Slab&display=swap');

body {
  margin: 0;
  background: #ffae00;
  font-family: 'Fira Code', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #000F37;
}

p {
  color: #000F37;
  font-size: 16px;
  max-width: 700px;
  padding: 0 20px;
  margin: 0 auto;
}

a {
  color: #2e3866;
  text-decoration: none;
  font-size: inherit;
}

strong {
  color: #2e3866;
  text-decoration: none;
  border: none;
  background: none;
  font-size: 16px;
  padding: 0;
  margin: 0;
}